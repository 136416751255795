<template>
  <div>
    <CRow style="max-width: 900px; margin-top:30px; padding-bottom: 30px; border-bottom: 1px solid #E4E4E4;">
      <CCol
        style="display: flex; flex-direction: column; width: 50%"
        sm="6"
        md="4"
        class="color-input"
      >
        <label style="margin-right: 10px">Color Primario: </label>
        <input type="color" v-model="editedCompanyConfig.primary_color" />
      </CCol>
      <CCol
        style="display: flex; flex-direction: column; width: 50%"
        sm="6"
        md="4"
        class="color-input"
      >
        <label style="margin-right: 10px">Color Secundario: </label>
        <input type="color" v-model="editedCompanyConfig.secondary_color" />
      </CCol>
      <CCol
        style="display: flex; flex-direction: column; width: 50%"
        sm="6"
        md="4"
        class="color-input"
      >
        <label style="margin-right: 10px">Color Terciario: </label>
        <input type="color" v-model="editedCompanyConfig.tertiary_color" />
      </CCol>
    </CRow>


    <CRow style="max-width: 900px; margin-top: 30px;  padding-bottom: 30px; border-bottom: 1px solid #E4E4E4;">
      <CCol
        style="display: flex; align-items: center; width: 50%"
        sm="6"
        md="4"
        class="color-input"
      >
        <label style="margin-bottom:0;">Seguridad de red</label>
        <CInputCheckbox 
          alignVertical="center"
          alignHorizontal="center"
          variant="opposite"
          color="dark"
          :checked.sync="editedCompanyConfig.security_network"
          class="ml-3 checkbox"
          style="display:flex; align-items:center;"
        />
      </CCol>
      <CCol
        style="display: flex; align-items: center; width: 50%"
        sm="6"
        md="4"
        class="color-input"
      >
        <label style="margin-bottom:0;">Login requerido</label>
        <CInputCheckbox 
          alignVertical="center"
          alignHorizontal="center"
          variant="opposite"
          color="dark"
          :checked.sync="editedCompanyConfig.login_required"
          class="ml-3 checkbox"
          style="display:flex; align-items:center;"

        />
      </CCol>
      <CCol
        style="display: flex; align-items: center; width: 50%"
        sm="6"
        md="4"
        class="color-input"
      >
        <label style="margin-bottom:0;">Camaras permitidas</label>
        <CInputCheckbox 
          alignVertical="center"
          alignHorizontal="center"
          variant="opposite"
          color="dark"
          :checked.sync="editedCompanyConfig.cameras_allowed"
          class="ml-3 checkbox"
          style="display:flex; align-items:center;"
        />
      </CCol>
    </CRow>
    <CRow style="max-width: 900px; margin-top: 15px; padding-bottom: 20px; border-bottom: 1px solid #E4E4E4;">
      <CCol
        style="display: flex; align-items: center; width: 50%"
        sm="6"
        md="4"
        class="color-input"
      >
        <CInput style="width: 100px;" type="number" label="Orden:" v-model="editedCompanyConfig.news_order" />
      </CCol>
    </CRow>


    <CRow style="max-width: 900px; margin-top:20px; padding-bottom: 20px; border-bottom: 1px solid #E4E4E4;">
      <CCol sm="6">
        <CSelect style="width:200px;" :options="optionsHomeType" label="Tipo de Home" :value.sync="editedCompanyConfig.home_type"></CSelect>
      </CCol>
    </CRow>

    <CRow style="max-width: 900px; margin-top:20px; padding-bottom: 20px; border-bottom: 1px solid #E4E4E4;">
        <CCol sm="6">
          <div style="display: flex;">
<!--             <label class="label-img">Imagen</label>
            <input class="file-custom" type="file" @change="onChangeImg"/> -->


<div class="input-group file-input-group" data-controller="file-input">
  <input :value="fileName" style="background-color: #fff;" class="form-control" type="text" placeholder="Selecionar archivo" readonly data-target="file-input.value">
  <input @change="onChangeImg" type="file" class="form-control" id="customFile" data-target="file-input.input" data-action="file-input#display">
  <div class="input-group-append">
    <label style="background-color: #fff;" class="btn btn-secondary mb-0" for="customFile"><img style="width:20px;" src="../../assets/icons/svg/clip.svg" alt=""></label>
  </div>
</div>
          </div>
        </CCol>
        <CCol sm="6">
            <img v-if="previewImg" class="img-preview" :src="previewImg" alt="imagen empresa">
        </CCol>
    </CRow>

      <div style="display:flex; justify-content: flex-end; max-width: 900px; margin-top:20px;">
        <div class="alert" style="padding:0;">
            <CAlert v-if="alertSuccess" color="success">Configuración actualizada</CAlert>
        </div>
        <div class="alert">
            <CAlert v-if="alertError" color="red">Error al acutalizar</CAlert>
        </div>
        <button
        class="saveBtn"
        @click="updateCompanyConfig()"
        >
        Guardar
        </button>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CFormSelect } from "@coreui/vue";
import { modifyCompanyConfig } from "../../api/companyConfig.js";


export default {
  component: {
    CFormSelect,
  },
  data() {
    return {
      optionsHomeType: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
      ],
      alertSuccess: false,
      alertError: false,
      editedCompanyConfig: {},
      previewImg: "",
      fileName: ''
    };
  },
  computed: {
    ...mapGetters(["configCompany"]),
/*     imgHasChanges() {
        const original_img = this.configCompany.image;
        return this.editedCompanyConfig.image == original_img ? false : true;
  }, */
  },
  methods: {
    onChangeImg(e) {
        this.editedCompanyConfig.image = e.target.files['0']
        this.previewImg = URL.createObjectURL(e.target.files['0'])
        this.fileName = e.target.files['0'].name
    },
    updateCompanyConfig() {
      let data = new FormData();
      const obj = {...this.editedCompanyConfig};
      +obj.news_order
      obj.login_required = this.editedCompanyConfig.login_required ? 1 : 0;
      obj.security_network = this.editedCompanyConfig.security_network ? 1 : 0;
      obj.cameras_allowed = this.editedCompanyConfig.cameras_allowed ? 1 : 0;
      if(typeof this.editedCompanyConfig.image == 'string') {
        delete obj.image
      }
  //validación solo se envían imágenes si es que cambiaron
    //!this.imgBGHasChanges && delete obj.image;

    //Se agregan propiedades a formData
    for (const property in obj) {
        data.append(property, obj[property]);
  }
/*   data.append('_method', 'PATCH') */

        modifyCompanyConfig(data).then((res) => {
                this.alertSuccess = true;
                setTimeout(() => {
                  this.alertSuccess = false;
                }, 1000);
            }).catch(e => {
              console.log('error',e);
              this.alertError = true;
              setTimeout(() => {
                this.alertError = false;
              }, 1000);
            })
        },
  },
  mounted() {
    this.editedCompanyConfig = { ...this.configCompany };
    this.previewImg = this.editedCompanyConfig.image;
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/style.scss';

.file-input-group {
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
.img-preview {
  width: 100px;
  height: 100px;
  object-fit: contain;
    margin-top: 15px;
}

.label-img {
  margin-right: 5px;
}
.alert {
  display: inline-block; 
  margin-left: 50px;
}
.saveBtn {
      background: transparent linear-gradient(180deg, $gradient-color-1 0%, $gradient-color-2 100%) 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    top: 410px;
    left: 339px;
    width: 123px;
    height: 37px;
    font: normal normal normal 14px/18px Montserrat;
    color: #fff;
    border: none;
}

.color-input {
  margin-top: 12px;
}
</style>

<template>
    <div style="margin-bottom: 30px; width: 90%; max-width: 1200px;">
      <div style=" display: flex; justify-content: space-between; margin-top: 30px; padding-bottom: 20px; border-bottom: 1px solid #E4E4E4;">
          <label>Estado Activo</label>
          <CSwitch
			    alignVertical="center"
			    alignHorizontal="center"
			    variant="opposite"
          color="success"
          :checked.sync="active"
			    shape="pill"
			    class="ml-3 switch"
            />
      </div>

<CRow v-if="active" style="max-width: 900px; margin-top: 30px;">
        <CCol sm="12" md="6">
          <CInput label="Título:" v-model="editedC2CConfig.title" />
        </CCol>
        <CCol sm="12" md="6">
          <CInput label="Subtítulo:" v-model="editedC2CConfig.subtitle" />
        </CCol>
        <CCol sm="12">
          <CInput label="Descripción:" v-model="editedC2CConfig.description" />
        </CCol>

        <CCol sm="12">
          <CRow>
        <CCol style="display:flex; align-items:center; " sm="6" md="2">
          <CInput style="width: 100px;" type="number" label="Orden:" v-model="editedC2CConfig.order" />
        </CCol>
        <CCol style="display:flex; flex-direction:column; " sm="6" md="2" class="color-input">
          <label>Color: </label>
          <input type="color" v-model="editedC2CConfig.color" />
        </CCol>
        </CRow>
        </CCol>
        <CCol sm="12">
          <CRow style="margin-top: 20px;">
        <CCol sm="6">

        <div class="input-group file-input-group" data-controller="file-input">
          <input :value="fileNameImgBG" style="background-color: #fff;" class="form-control" type="text" placeholder="Selecionar archivo" readonly data-target="file-input.value">
          <input @change="onChangeImgBG" type="file" class="form-control" id="customFile" data-target="file-input.input" data-action="file-input#display">
          <div class="input-group-append">
            <label style="background-color: #fff;" class="btn btn-secondary mb-0" for="customFile"><img style="width:20px;" src="../../assets/icons/svg/clip.svg" alt=""></label>
          </div>
        </div>
          <img v-if="previewImgBG" class="img-preview" :src="previewImgBG" alt="preview">
        </CCol>
        <CCol sm="6">

          <div class="input-group file-input-group" data-controller="file-input">
          <input :value="fileNameImgLabel" style="background-color: #fff;" class="form-control" type="text" placeholder="Selecionar archivo" readonly data-target="file-input1.value">
          <input @change="onChangeImgLabel" type="file" class="form-control" id="customFile1" data-target="file-input1.input" data-action="file-input1#display">
          <div class="input-group-append">
            <label style="background-color: #fff;" class="btn btn-secondary mb-0" for="customFile1"><img style="width:20px;" src="../../assets/icons/svg/clip.svg" alt=""></label>
          </div>
        </div>
        <img v-if="previewImgLabel" class="img-preview" :src="previewImgLabel" alt="preview">
        </CCol>
        </CRow>
        <div style="display:flex; justify-content: flex-end; max-width: 900px; margin-top:20px;">
        <div  class="alert" style="padding:0; margin-left: 0; margin-right:8px;">
          <CAlert v-if="alertSuccess" color="success">Configuración actualizada</CAlert>
        </div>
        <div class="alert" style="padding:0; margin-left: 0; margin-right:8px;">
          <CAlert v-if="alertError" color="red">Error al acutalizar</CAlert>
        </div>
        <button
          class="saveBtn"
          @click="updateC2CConfig()"
        >
          Guardar
        </button>
        </div>
        </CCol>
      </CRow>

    </div>
</template>

<script>
import { mapGetters } from "vuex"
import {modifyClick2CallConfig} from '../../api/companyConfig.js'

export default {
    data () {
        return {
            alertSuccess: false,
            alertError: false,
            editedC2CConfig: {},
            previewImgBG: '',
            previewImgLabel: '',
            active: null,
            fileNameImgBG: "",
            fileNameImgLabel: ""
        }
    },
    methods: {
    updateStatus(active){
      const data = {active, _method: 'PATCH'}
      modifyClick2CallConfig(data).then(res=> {
        this.editedC2CConfig = res.c2cconfig;
      })
    },
    updateC2CConfig() {
      let data = new FormData();
      const obj = {...this.editedC2CConfig};
      +obj.order
      obj.active = this.active ? 1 : 0;

    //Se agregan propiedades a formData
    for (const property in obj) {
      if (obj[property] !== this.configClick2call[property] && obj[property]) {
        data.append(property, obj[property]);
      }
  }
  data.append('_method', 'PATCH')

        modifyClick2CallConfig(data).then((res) => {
                this.alertSuccess = true;
                setTimeout(() => {
                  this.alertSuccess = false;
                }, 1000);
            }).catch(e => {
              this.alertError = true;
              setTimeout(() => {
                this.alertError = false;
              }, 1000);
            })
        },
        onChangeImgBG(e) {
        this.editedC2CConfig.bg_image = e.target.files["0"]
        this.previewImgBG = URL.createObjectURL(e.target.files["0"])
        this.fileNameImgBG = e.target.files["0"].name
    },
    onChangeImgLabel(e) {
      this.editedC2CConfig.img_icon = e.target.files["0"]
      this.previewImgLabel = URL.createObjectURL(e.target.files["0"])
      this.fileNameImgLabel = e.target.files["0"].name
    },
    },
  computed: {
    ...mapGetters(["configClick2call"]),
    },
    mounted() {
        this.active = this.configClick2call.active

        this.editedC2CConfig = {...this.configClick2call}
        this.previewImgBG = this.editedC2CConfig.bg_image
        this.previewImgLabel = this.editedC2CConfig.img_icon
    },
    watch: {
      active(val) {
        this.updateStatus(val)
      },
      editedC2CConfig(val) {
        this.previewImgBG = this.editedC2CConfig.bg_image
        this.previewImgLabel = this.editedC2CConfig.img_icon
      },
}
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/style.scss';
.color-input {
  display: flex;
  align-items: center;
  gap: 20px;
}
.color-input label {
  margin-bottom: 0;
}
.img-preview {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.label-img {
  margin-right: 5px;
}
.select-cierre-tipo .vs__search::placeholder,
.select-cierre-tipo .vs__dropdown-toggle,
.select-cierre-tipo .vs__dropdown-menu,
.select-cierre-tipo .vs__open-indicator {
  color: #d0282e;
  border-color: #d0282e;
}
.switch {
	vertical-align: middle!important;
}
.c-switch-slider {
    border: 1px solid $color-primary!important;
}
.c-switch-slider::before {
    border: 1px solid $color-primary!important;
}

.c-switch-opposite-dark .c-switch-input:checked + .c-switch-slider::before {
    background-color: $color-primary !important;
}

.saveBtn {
      background: transparent linear-gradient(180deg, $gradient-color-1 0%, $gradient-color-2 100%) 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    top: 410px;
    left: 339px;
    width: 123px;
    height: 37px;
    font: normal normal normal 14px/18px Montserrat;
    color: #fff;
    border: none;
}
.file-input-group {
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
.alert {
  display: inline-block; 
  margin-left: 50px;
}
</style>
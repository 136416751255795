<template>
  <div style="margin-bottom: 30px;margin-top: 30px;width: 90%; max-width: 1200px;">
          <div style=" display: flex; justify-content: space-between; margin-top: 30px; padding-bottom: 20px; border-bottom: 1px solid #E4E4E4;">
    <label>Estado Activo</label>
    <CSwitch
      alignVertical="center"
      alignHorizontal="center"
      variant="opposite"
      color="success"
      :checked.sync="active"
      shape="pill"
      class="ml-3 switch"
    />
    </div>

    <CRow v-if="active" style="max-width: 600px;">
      <CCol sm="12" md="6">
        <CInput label="Título" v-model="editedTwitterConfig.username" />
      </CCol>
      <CCol sm="12" md="6">
        <CInput type="number" label="Orden:" v-model="editedTwitterConfig.order" />
      </CCol>

        <button
        class="saveBtn"
            style="margin-left: 15px;"
          @click="updateTwitterConfig()"
        >
          Guardar
        </button>
        <div class="alert">
          <CAlert v-if="alertSuccess" color="success">Configuración actualizada</CAlert>
        </div>
        <div class="alert">
          <CAlert v-if="alertError" color="red">Error al acutalizar</CAlert>
        </div>
    </CRow>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { modifyTwitterConfig } from "../../api/companyConfig.js";

export default {
  data() {
    return {
      active: null,
      alertSuccess: false,
      alertError: false,
      editedTwitterConfig: {},
    };
  },
    computed: {
    ...mapGetters(["configTwitter"]),
    },
  methods: {
    updateStatus(active){
    const data = {active, _method: 'PATCH'}
      modifyTwitterConfig(data).then(res=> {
        this.editedTwitterConfig = res.config;
      })
    },
    updateTwitterConfig() {
      const obj = { ...this.editedTwitterConfig };
      +obj.order;
      obj.active = this.editedTwitterConfig ? 1 : 0;
      obj._method = "PATCH";
      modifyTwitterConfig(obj)
        .then((res) => {
          this.alertSuccess = true;
          setTimeout(() => {
            this.alertSuccess = false;
          }, 1000);
        })
        .catch((e) => {
          this.alertError = true;
          setTimeout(() => {
            this.alertError = false;
          }, 1000);
        });
    },
  },
      watch: {
      active(val) {
        this.updateStatus(val)
      }
},
      mounted() {
        this.active = this.configTwitter.active
        this.editedTwitterConfig = {...this.configTwitter}
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/style.scss';

.saveBtn {
    background: transparent linear-gradient(180deg, $gradient-color-1 0%, $gradient-color-2 100%) 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    top: 410px;
    left: 339px;
    width: 123px;
    height: 37px;
    font: normal normal normal 14px/18px Montserrat;
    color: #fff;
    border: none;
}</style>

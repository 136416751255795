<template>
  <div style="margin-top: 30px">
    <div :style="[backBtn ? { 'justify-content': 'space-between' } : { 'justify-content': 'flex-end' },]" style=" display: flex; justify-content: space-between; width: 90%; max-width: 1200px;">
      <CButton
        class="mr-2 mb-2"
        color="secondary"
        square
        size="sm"
        @click="getTypes(null)"
        v-show="backBtn"
      >
        Volver a Tipos de denuncia
      </CButton>
      <button
        class="saveBtn mr-2"
        @click="() => (categoriesModal = !categoriesModal)"
      >
        Categorías
      </button>
      <button class="saveBtn" @click="() => (createModal = !createModal)">
        Crear Tipo de Denuncia
      </button>
    </div>

    <!--     Tabla Tipos de denuncia -->
    <CDataTable
      :fields="fields"
      :items="items"
      :items-per-page="10"
      hover
      sorter
      pagination
      style="max-width: 850px"
    >
      <template #action="{ item }">
        <td class="py-2">
          <CButton
            v-if="item.subtypes_count > 0"
            class="mr-2"
            color="danger"
            variant="outline"
            square
            size="sm"
            @click="getTypes(item.id)"
          >
            Ver Subtipos
          </CButton>

          <CButton
            class="mr-2"
            color="danger"
            variant="outline"
            square
            size="sm"
            @click="setCurrentItem(item, 'edit')"
          >
            <img
              style="width: 1rem"
              src="../../assets/icons/svg/edit.svg"
              alt=""
            />
          </CButton>
          <CButton
            v-if="item.parent_id"
            color="red"
            variant="outline"
            square
            size="sm"
            @click="setCurrentItem(item, 'delete')"
          >
            <img
              style="width: 1rem"
              src="../../assets/icons/svg/delete.svg"
              alt=""
            />
          </CButton>

          <div
            style="display: inline-block; margin-left: 12px"
            @click.stop="updateActive(item)"
          >
            <CInputCheckbox
              v-show="!loader"
              :checked.sync="item.active"
              label="Activar"
            />

            <CSpinner
              v-show="loader"
              color="primary"
              style="width: 1rem; height: 1rem"
            />
          </div>
        </td>
      </template>
    </CDataTable>

    <!--     Modal crear -->
    <CModal
      style="width: 40% !important"
      class="modal-sm"
      title="Crear tipo de denuncia"
      color="warning"
      :show.sync="createModal"
      size="sm"
    >
      <CRow>
        <CCol sm="12">
          <CInput label="Título" v-model="newType.title" />
        </CCol>
        <CCol sm="12">
          <CInput label="Descripción" v-model="newType.description" />
        </CCol>
        <CCol sm="12">
          <CRow>
            <CCol sm="12" md="6">
              <CSelect
                placeholder="Seleccione una opción"
                v-model="newType.complaint_type_id"
                label="Denuncia original"
                :options="optionsParent_id"
              />
            </CCol>
            <CCol sm="12" md="6">
              <CSelect
                placeholder="Seleccione una opción"
                v-model="newType.complaint_category"
                label="Categoría"
                :options="optionsCategory"
              />
            </CCol>
            <CCol sm="12" md="6" v-if="optionsType_id.length">
              <CSelect
                placeholder="Seleccione una opción"
                v-model="newType.parent_id"
                label="Tipo denuncia Padre"
                :options="optionsType_id"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12">
          <CRow>
            <CCol sm="6">
              <CInput type="number" label="Orden" v-model="newType.order" />
            </CCol>
            <CCol sm="6" class="color-input">
              <label>Color: </label>
              <input type="color" v-model="newType.color" />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12">
          <CRow>
            <CCol sm="6" md="4">
              <!--           <label class="label-img">Imagen principal: </label>
          <input type="file" @change="onChangeImgCreate"/>
          <img v-if="previewImgCreate" class="img-preview" :src="previewImgCreate" alt="preview"> -->
              <label class="label-img">Imagen principal: </label>
              <div
                class="input-group file-input-group"
                data-controller="file-input"
              >
                <input
                  :value="fileNameImgCreate"
                  style="background-color: #fff"
                  class="form-control"
                  type="text"
                  placeholder="Selecionar archivo"
                  readonly
                  data-target="file-input.value"
                />
                <input
                  @change="onChangeImgCreate"
                  type="file"
                  class="form-control"
                  id="customFile"
                  data-target="file-input.input"
                  data-action="file-input#display"
                />
                <div class="input-group-append">
                  <label
                    style="background-color: #fff"
                    class="btn btn-secondary mb-0"
                    for="customFile"
                    ><img
                      style="width: 18px"
                      src="../../assets/icons/svg/clip.svg"
                      alt=""
                  /></label>
                </div>
              </div>
              <img
                v-if="previewImgCreate"
                class="img-preview"
                :src="previewImgCreate"
                alt="preview"
              />
            </CCol>
            <CCol sm="6" md="4">
              <!--           <label class="label-img">Imagen marcador: </label>
          <input type="file"  @change="onChangeMarkerCreate"/>
          <img v-if="previewMarkerCreate" class="img-preview" :src="previewMarkerCreate" alt="preview"> -->

              <label class="label-img">Imagen marcador: </label>
              <div
                class="input-group file-input-group"
                data-controller="file-input1"
              >
                <input
                  :value="fileNameMarkerCreate"
                  style="background-color: #fff"
                  class="form-control"
                  type="text"
                  placeholder="Selecionar archivo"
                  readonly
                  data-target="file-input1.value"
                />
                <input
                  @change="onChangeMarkerCreate"
                  type="file"
                  class="form-control"
                  id="customFile1"
                  data-target="file-input1.input"
                  data-action="file-input1#display"
                />
                <div class="input-group-append">
                  <label
                    style="background-color: #fff"
                    class="btn btn-secondary mb-0"
                    for="customFile1"
                    ><img
                      style="width: 18px"
                      src="../../assets/icons/svg/clip.svg"
                      alt=""
                  /></label>
                </div>
              </div>
              <img
                v-if="previewMarkerCreate"
                class="img-preview"
                :src="previewMarkerCreate"
                alt="preview"
              />
            </CCol>
            <CCol sm="6" md="4">
              <!--           <label class="label-img">Icono principal: </label>
          <input type="file"  @change="onChangeMainIconCreate"/>
          <img v-if="previewMainIconCreate" class="img-preview" :src="previewMainIconCreate" alt="preview"> -->

              <label class="label-img">Icono principal: </label>
              <div
                class="input-group file-input-group"
                data-controller="file-input2"
              >
                <input
                  :value="fileNameIconCreate"
                  style="background-color: #fff"
                  class="form-control"
                  type="text"
                  placeholder="Selecionar archivo"
                  readonly
                  data-target="file-input2.value"
                />
                <input
                  @change="onChangeMainIconCreate"
                  type="file"
                  class="form-control"
                  id="customFile2"
                  data-target="file-input2.input"
                  data-action="file-input2#display"
                />
                <div class="input-group-append">
                  <label
                    style="background-color: #fff"
                    class="btn btn-secondary mb-0"
                    for="customFile2"
                    ><img
                      style="width: 18px"
                      src="../../assets/icons/svg/clip.svg"
                      alt=""
                  /></label>
                </div>
              </div>
              <img
                v-if="previewMainIconCreate"
                class="img-preview"
                :src="previewMainIconCreate"
                alt="preview"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <div slot="footer" class="w-100">
        <button
          class="mr-2"
          color="danger"
          square
          :disabled="
            !newType.title ||
            !newType.description ||
            !newType.parent_id ||
            !newType.complaint_category ||
            !newType.order ||
            !newType.color ||
            !newType.main_image ||
            !newType.marker_icon
          "
          size="sm"
          @click="createType()"
        >
          Guardar
        </button>
        <CButton
          color="red"
          square
          size="sm"
          @click="() => (createModal = !createModal)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!--     Modal Eliminar -->
    <CModal
      title="Eliminar tipo de denuncia"
      color="warning"
      :show.sync="deleteModal"
      size="sm"
    >
      ¿Seguro que quieres eliminar el tipo de denuncia
      <span style="font-weight: 800">{{ currentType.title }}</span
      >?
      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="red"
          variant="outline"
          square
          @click="deleteType()"
        >
          Eliminar
        </CButton>
        <CButton
          color="secondary"
          variant="outline"
          square
          @click="() => (deleteModal = !deleteModal)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!--      Modal editar -->
    <CModal
      class="modal-sm"
      title="Editar tipo de denuncia"
      color="warning"
      :show.sync="editModal"
      size="sm"
    >
      <CRow>
        <CCol sm="12">
          <CInput label="Título" v-model="currentType.title" />
        </CCol>
        <CCol sm="12">
          <CInput label="Descripción" v-model="currentType.description" />
        </CCol>
        <CCol sm="12">
          <CRow>
            <CCol sm="12" md="6">
              <CSelect
                placeholder="Seleccione una opción"
                v-model="currentType.complaint_type_id"
                label="Tipo Denuncia Base"
                :options="optionsParent_id"
              />
            </CCol>
            <CCol sm="12" md="6">
              <CSelect
                placeholder="Seleccione una opción"
                v-model="currentType.complaint_category"
                label="Categoría"
                :options="optionsCategory"
              />
            </CCol>
            <CCol sm="12" md="6">
              <CSelect
                placeholder="Seleccione una opción"
                v-model="currentType.parent_id"
                label="Tipo denuncia Padre"
                :options="optionsType_id"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12">
          <CRow>
            <CCol sm="6">
              <CInput type="number" label="Orden" v-model="currentType.order" />
            </CCol>
            <CCol sm="6" class="color-input">
              <label>Color: </label>
              <input type="color" v-model="currentType.color" />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12">
          <CRow>
            <CCol sm="6" md="4">
              <!--           <label class="label-img">Imagen principal: </label>
          <input type="file" @change="onChangeImgUpdate"/>
          <img v-if="previewImgUpdate" class="img-preview" :src="previewImgUpdate" alt="preview"> -->

              <label class="label-img">Imagen principal: </label>
              <div
                class="input-group file-input-group"
                data-controller="file-input3"
              >
                <input
                  :value="fileNameImgUpdate"
                  style="background-color: #fff"
                  class="form-control"
                  type="text"
                  placeholder="Selecionar archivo"
                  readonly
                  data-target="file-input3.value"
                />
                <input
                  @change="onChangeImgUpdate"
                  type="file"
                  class="form-control"
                  id="customFile3"
                  data-target="file-input3.input"
                  data-action="file-input3#display"
                />
                <div class="input-group-append">
                  <label
                    style="background-color: #fff"
                    class="btn btn-secondary mb-0"
                    for="customFile3"
                    ><img
                      style="width: 18px"
                      src="../../assets/icons/svg/clip.svg"
                      alt=""
                  /></label>
                </div>
              </div>
              <img
                v-if="previewImgUpdate"
                class="img-preview"
                :src="previewImgUpdate"
                alt="preview"
              />
            </CCol>
            <CCol sm="6" md="4">
              <!--           <label class="label-img">Imagen marcador: </label>
          <input type="file"  @change="onChangeMarkerUpdate"/>
          <img v-if="previewMarkerUpdate" class="img-preview" :src="previewMarkerUpdate" alt="preview"> -->

              <label class="label-img">Imagen marcador: </label>
              <div
                class="input-group file-input-group"
                data-controller="file-input4"
              >
                <input
                  :value="fileNameMarkerUpdate"
                  style="background-color: #fff"
                  class="form-control"
                  type="text"
                  placeholder="Selecionar archivo"
                  readonly
                  data-target="file-input4.value"
                />
                <input
                  @change="onChangeMarkerUpdate"
                  type="file"
                  class="form-control"
                  id="customFile4"
                  data-target="file-input4.input"
                  data-action="file-input4#display"
                />
                <div class="input-group-append">
                  <label
                    style="background-color: #fff"
                    class="btn btn-secondary mb-0"
                    for="customFile4"
                    ><img
                      style="width: 18px"
                      src="../../assets/icons/svg/clip.svg"
                      alt=""
                  /></label>
                </div>
              </div>
              <img
                v-if="previewMarkerUpdate"
                class="img-preview"
                :src="previewMarkerUpdate"
                alt="preview"
              />
            </CCol>
            <CCol sm="6" md="4">
              <!--           <label class="label-img">Icono principal: </label>
          <input type="file"  @change="onChangeMainIconUpdate"/>
          <img v-if="previewMainIconUpdate" class="img-preview" :src="previewMainIconUpdate" alt="preview"> -->

              <label class="label-img">Icono principal: </label>
              <div
                class="input-group file-input-group"
                data-controller="file-input5"
              >
                <input
                  :value="fileNameMainMarkerUpdate"
                  style="background-color: #fff"
                  class="form-control"
                  type="text"
                  placeholder="Selecionar archivo"
                  readonly
                  data-target="file-input5.value"
                />
                <input
                  @change="onChangeMainIconUpdate"
                  type="file"
                  class="form-control"
                  id="customFile5"
                  data-target="file-input5.input"
                  data-action="file-input5#display"
                />
                <div class="input-group-append">
                  <label
                    style="background-color: #fff"
                    class="btn btn-secondary mb-0"
                    for="customFile5"
                    ><img
                      style="width: 18px"
                      src="../../assets/icons/svg/clip.svg"
                      alt=""
                  /></label>
                </div>
              </div>
              <img
                v-if="previewMainIconUpdate"
                class="img-preview"
                :src="previewMainIconUpdate"
                alt="preview"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="danger"
          square
          size="sm"
          :disabled="
            !currentType.title ||
            !currentType.description ||
            !currentType.parent_id ||
            !currentType.complaint_category ||
            !currentType.order ||
            !currentType.color ||
            !currentType.main_image ||
            !currentType.marker_icon
          "
          @click="updateType()"
        >
          Guardar
        </CButton>
        <CButton
          color="red"
          square
          size="sm"
          @click="() => (editModal = !editModal)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!--          Modal Categorías -->
    <CModal
      class="modal-sm"
      title="Categorías"
      color="warning"
      :show.sync="categoriesModal"
      size="sm"
    >
      <button class="saveBtn mr-2" @click="newCategory">Nueva Categoría</button>
      <CDataTable
        :fields="fieldsCategories"
        :items="categories"
        :items-per-page="10"
        hover
        sorter
        pagination
        style="max-width: 850px"
      >
        <template #action="{ item }">
          <td class="py-2">
            <CButton
              class="mr-2"
              color="danger"
              variant="outline"
              square
              size="sm"
              @click="setCurrentCategory(item, 'edit')"
            >
              <img
                style="width: 1rem"
                src="../../assets/icons/svg/edit.svg"
                alt=""
              />
            </CButton>
            <CButton
              color="red"
              variant="outline"
              square
              size="sm"
              @click="setCurrentCategory(item, 'delete')"
            >
              <img
                style="width: 1rem"
                src="../../assets/icons/svg/delete.svg"
                alt=""
              />
            </CButton>
          </td>
        </template>
      </CDataTable>

      <CRow>
        <CCol sm="12" md="6">
          <CInput label="Título" v-model="currentCategory.name" />
        </CCol>
        <CCol sm="12" md="6">
          <CInput label="Descripción" v-model="currentCategory.description" />
        </CCol>
        <CCol sm="12" md="6">
          <CInputCheckbox
            :checked.sync="currentCategory.public"
            label="Público"
          />
        </CCol>
        <CCol sm="12" md="6">
          <CInputCheckbox
            :checked.sync="currentCategory.citation"
            label="Citación"
          />
        </CCol>
        <CCol sm="12" md="6">
          <label class="label-img">Imagen: </label>
          <div
            class="input-group file-input-group"
            data-controller="file-input"
          >
            <input
              :value="fileNameImgCategory"
              style="background-color: #fff"
              class="form-control"
              type="text"
              placeholder="Selecionar archivo"
              readonly
              data-target="file-input6.value"
            />
            <input
              @change="onChangeImgCategory"
              type="file"
              class="form-control"
              id="customFile6"
              data-target="file-input6.input"
              data-action="file-input6#display"
            />
            <div class="input-group-append">
              <label
                style="background-color: #fff"
                class="btn btn-secondary mb-0"
                for="customFile6"
                ><img
                  style="width: 18px"
                  src="../../assets/icons/svg/clip.svg"
                  alt=""
              /></label>
            </div>
          </div>
          <img
            v-if="previewImgCategory"
            class="img-preview"
            :src="previewImgCategory"
            alt="preview"
          />
        </CCol>
      </CRow>

      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="success"
          square
          :disabled="!currentCategory.name"
          size="sm"
          @click="createOrUpdate == 'create' ? createCategory() : updateCategory()">
          Guardar
        </CButton>
        <CButton
          color="red"
          square
          size="sm"
          @click="() => (categoriesModal = !categoriesModal)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import {
  getComplaintTypes,
  getTypeInfo,
  createComplaintType,
  updateComplaintType,
  deleteComplaintType,
  getCompanyComplaintCategories,
  addComplaintCategory,
  removeComplaintCategories,
  updateComplaintCategory
} from "../../api/complaints";
import { CDataTable, CModal, CFormCheck } from "@coreui/vue";
import { mapState } from "vuex";

export default {
  components: {
    CDataTable,
    CModal,
    CFormCheck,
  },
  data() {
    return {
      //modales
      editModal: false,
      createModal: false,
      deleteModal: false,
      categoriesModal: false,

      //Select
      optionsParent_id: [],
      optionsCategory: [],
      optionsType_id: [],
      optionsType_idDefault: [],

      //preview imágenes
      previewImgCreate: "",
      previewMarkerCreate: "",
      previewMainIconCreate: "",
      previewImgUpdate: "",
      previewMarkerUpdate: "",
      previewMainIconUpdate: "",
      previewImgCategory: "",

      //nombre archivo
      fileNameImgCreate: "",
      fileNameMarkerCreate: "",
      fileNameIconCreate: "",
      fileNameImgUpdate: "",
      fileNameMarkerUpdate: "",
      fileNameMainMarkerUpdate: "",
      fileNameImgCategory: "",

      currentType: "",
      newType: {
        complaint_type_id: null,
        title: "",
        description: "",
        complaint_category: "",
        parent_id: "",
        main_image: "",
        marker_icon: "",
        color: "",
        order: "",
        main_icon: "",
      },

      currentCategory: {
        name: "",
        description: "",
        image: "",
        public: false,
        citation: false,
      },
      createOrUpdate: 'create',

      //tabla
      items: [],
      fields: [
        {
          key: "title",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
      loader: false,
      backBtn: false,

      //tabla categorías
      categories: [],
      fieldsCategories: [
        {
          key: "name",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
    };
  },
  computed: {
    ...mapState(["userData"]),
    imgMainHasChanges() {
      const original_img = this.items.filter(
        (type) => type.id == this.currentType.id
      )[0].main_image;
      return this.currentType.main_image == original_img ? false : true;
    },
    imgMarkerHasChanges() {
      const original_img = this.items.filter(
        (type) => type.id == this.currentType.id
      )[0].marker_icon;
      return this.currentType.marker_icon == original_img ? false : true;
    },
    imgMainIconHasChanges() {
      const original_img = this.items.filter(
        (type) => type.id == this.currentType.id
      )[0].main_icon;
      return this.currentType.main_icon == original_img ? false : true;
    },
  },
  methods: {
    setCurrentItem(item, modal) {
      this.currentType = { ...item };

      //qué modal se abre
      modal == "edit"
        ? (this.editModal = !this.editModal)
        : (this.deleteModal = !this.deleteModal);

      //preview imágenes
      this.previewImgUpdate = this.currentType.main_image
        ? this.currentType.main_image
        : "";
      this.previewMarkerUpdate = this.currentType.marker_icon
        ? this.currentType.marker_icon
        : "";
      this.previewMainIconUpdate = this.currentType.main_icon
        ? this.currentType.main_icon
        : "";

      //se valida que no pueda ser su propia categoría padre
      this.optionsType_id = this.optionsType_idDefault.filter(
        (option) => option.value !== this.currentType.id
      );
    },
    onChangeImgCreate(e) {
      this.newType.main_image = e.target.files["0"];
      this.previewImgCreate = URL.createObjectURL(e.target.files["0"]);
      this.fileNameImgCreate = e.target.files["0"].name;
    },
    onChangeMarkerCreate(e) {
      this.newType.marker_icon = e.target.files["0"];
      this.previewMarkerCreate = URL.createObjectURL(e.target.files["0"]);
      this.fileNameMarkerCreate = e.target.files["0"].name;
    },
    onChangeMainIconCreate(e) {
      this.newType.main_icon = e.target.files["0"];
      this.previewMainIconCreate = URL.createObjectURL(e.target.files["0"]);
      this.fileNameIconCreate = e.target.files["0"].name;
    },
    onChangeImgUpdate(e) {
      this.currentType.main_image = e.target.files["0"];
      this.previewImgUpdate = URL.createObjectURL(e.target.files["0"]);
      this.fileNameImgUpdate = e.target.files["0"].name;
    },
    onChangeMarkerUpdate(e) {
      this.currentType.marker_icon = e.target.files[0];
      this.previewMarkerUpdate = URL.createObjectURL(e.target.files[0]);
      this.fileNameMarkerUpdate = e.target.files["0"].name;
    },
    onChangeMainIconUpdate(e) {
      this.currentType.main_icon = e.target.files[0];
      this.previewMainIconUpdate = URL.createObjectURL(e.target.files[0]);
      this.fileNameMainMarkerUpdate = e.target.files["0"].name;
    },
    onChangeImgCategory(e) {
      this.currentCategory.image = e.target.files[0];
      this.previewImgCategory = URL.createObjectURL(e.target.files[0]);
      this.fileNameImgCategory = e.target.files["0"].name;
    },
    createType() {
      const data = new FormData();
      for (const property in this.newType) {
        data.append(property, this.newType[property]);
      }
      data.append("company_id", this.userData.role.company_id);
      createComplaintType(data).then((res) => {
        this.createModal = !this.createModal;
        this.getTypes();
      });
    },
    deleteType() {
      deleteComplaintType(this.currentType.id).then((res) => {
        this.deleteModal = !this.deleteModal;
        this.getTypes();
      });
    },
    updateType() {
      const data = new FormData();
      const obj = { ...this.currentType };
      +obj.order;

      //validación solo se envían imágenes si es que cambiaron
      !this.imgMainHasChanges && delete obj.main_image;
      !this.imgMarkerHasChanges && delete obj.marker_icon;
      !this.imgMainIconHasChanges && delete obj.main_icon;

      //Se agregan propiedades a formData
      for (const property in obj) {
        data.append(property, obj[property]);
      }
      data.append("company_complaint_type_id", obj.id);
      data.append("_method", "PATCH");
      updateComplaintType(data).then((res) => {
        this.editModal = !this.editModal;
        this.getTypes();
      });
    },
    updateActive(item) {
      this.loader = true;
      setTimeout(() => {
        this.loader = false;
      }, 500);

      const data = new FormData();
      this.currentType = { ...item };
      const active = this.currentType.active ? 0 : 1;

      const obj = { active, company_complaint_type_id: this.currentType.id };
      for (const property in obj) {
        data.append(property, obj[property]);
      }
      data.append("_method", "PATCH");
      updateComplaintType(data).then((res) => {
        this.getTypes();
      });
    },
    getTypes(id = "") {
      this.backBtn = !id ? false : true;
      getComplaintTypes(id).then((res) => {
        //get Subtypes o Types si no hay id
        const regionTypes = res.complaint_types;
        this.items = regionTypes;

        this.optionsType_idDefault.push({
          value: regionTypes.id,
          label: regionTypes.title,
        });

      if (id) {
        this.optionsType_id = this.optionsType_idDefault.filter(
          (option) => option.value !== this.currentType.id
        );
        this.newType.parent_id = id
      } else {
        this.newType.parent_id = null
      }
      });
    },
    getInfo() {
      getTypeInfo().then((res) => {
        //opciones select edición
        this.optionsParent_id = res.base_complaint_types.map((parent) => {
          return { value: parent.id, label: parent.title };
        });
        /* 
        this.optionsCategory = res.complaint_categories.map(category => {
          return {value: category.id, label: category.name}
        }) */

        this.optionsType_idDefault = res.parent_complaint_types.map((type) => {
          return { value: type.id, label: type.title };
        });
      });
    },
    getCategories() {
      getCompanyComplaintCategories().then((res) => {
        this.optionsCategory = res.categories.map(category => {
          return {value: category.id, label: category.citation ? category.name + ' (Citación)' : category.name} 
        })

        this.categories = [...res.categories];
      });
    },
    createCategory() {
      const data = new FormData();
      const category = { ...this.currentCategory };
      category.public = category.public ? 1 : 0;
      category.citation = category.citation ? 1 : 0;

      for (const property in category) {
        data.append(property, category[property]);
      }

      addComplaintCategory(data).then((res) => {
        this.categories.push(res.category);
        this.getCategories()
        this.currentCategory = {
          name: "",
          description: "",
          image: "",
          public: false,
          citation: false,
        };

        this.fileNameImgCategory = "";
        this.previewImgCategory = "";
      });
    },
    setCurrentCategory(item, action) {
      if (action == "delete") {
        removeComplaintCategories({ complaint_category_id: item.id }).then(res => {
            this.categories = [...this.categories.filter((category) => category.id !== item.id)];
          }
        );
      } else if (action == "edit") {
        this.createOrUpdate = 'update'
        this.currentCategory = {...this.categories.filter(category => category.id == item.id)[0]};
        this.previewImgCategory = this.currentCategory.image

      }
    },
    updateCategory() {
      const data = new FormData();

      const obj = {...this.currentCategory}
      if(typeof obj.image == 'string') {
        delete obj.image
      }
      obj.public = obj.public ? 1 : 0;
      obj.citation = obj.citation ? 1 : 0;
      obj.complaint_category_id = this.currentCategory.id
      for (const property in obj) {
        data.append(property, obj[property]);
      }
      data.append('_method', 'PATCH')

      updateComplaintCategory(data).then(res => {
        this.getCategories()
        this.createOrUpdate = 'create'
        this.currentCategory = {
          name: "",
          description: "",
          image: "",
          public: false,
          citation: false,
        };
        this.previewImgCategory = ''
        this.fileNameImgCategory = ''
      })
    },
    newCategory() {
      this.createOrUpdate = 'create'
      this.currentCategory = {
        name: "",
        description: "",
        image: "",
        public: false,
        citation: false,
      };
      this.previewImgCategory = ''
      this.fileNameImgCategory = ''
    },
  },
  created() {
    this.getTypes(null);
    this.getInfo();
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/style.scss";

.file-input-group {
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.color-input {
  display: flex;
  align-items: center;
  gap: 20px;
}
.color-input label {
  margin-bottom: 0;
}
.img-preview {
  margin-top: 8px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.label-img {
  margin-right: 5px;
}
.saveBtn {
  background: transparent
    linear-gradient(180deg, $gradient-color-1 0%, $gradient-color-2 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  top: 410px;
  left: 339px;
  width: 123px;
  height: 37px;
  font: normal normal normal 14px/18px Montserrat;
  color: #fff;
  border: none;
}
</style>
